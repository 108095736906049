var jQuery = require('jquery');
window.$ = window.jQuery = jQuery;

import tether from 'tether';
global.Tether = tether;

require('bootstrap'); 
require('js-cookie');

import Cookies from 'js-cookie';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

$( document ).ready(function() {
	var thirst = Cookies.get('dismissed-thirst');
	var dismissed = "";

	$(window).scroll(function() {
		if(dismissed != 'true'){
			if( thirst != 'true') {
				if ($('.post-content').height() <= ($(window).height() + $(window).scrollTop()) - 450 && $('.twitter-thirst:visible').length == 0) {
					$('.twitter-thirst').fadeIn();
				};
			}
		}
	});

	$(".close").click(function(){
		//Set short term variable to not piss off the user if they dismiss
		dismissed = "true";
		//Set a cookie to not annoy the user
		Cookies.set('dismissed-thirst', 'true', { expires: 14 });
		$('.twitter-thirst').fadeOut();

	});

	let theme = "light";
	if(document.body.classList.contains("darkMode")) {
		theme = "dark";
	}

	$("#dark").click(function(e) {
		e.preventDefault();
		document.body.classList.toggle('darkMode');
		theme = (theme === "light" ? "dark" : "light");

		if(theme === "light") {
			$("#dark").text("☀️");
			Cookies.set('theme', 'lightMode', { expires: 100 });
		} else if(theme == "dark") {
			$("#dark").text("🌙");
			Cookies.set('theme', 'darkMode', { expires: 100 });
		}


	});
});

